import { gql } from '@apollo/client';

export const GET_PATIENT_QUERY = gql`
  query GetPatientQuery($data: PatientQueryInput!) {
    getPatientQuery(data: $data) {
      data {
        firstName
        lastName
        email
        age
        patientId
        race
        gender
        city
        state
        zip
        phone
        ethnicity
        militaryStatus
        optionalPatientSiteId
        createdBy
        createdUser
        createdAt
      }
      count
    }
  }
`;
