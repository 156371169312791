import { styles } from './styles';
import Heading from '../../../../sharedComponents/heading';
import {
  RACE_OPTIONS,
  ETHNICITY_OPTIONS,
  MILITARY_OPTIONS,
  ROLES
} from '../../../../utils/constants';
import Dropdown from '../../../../sharedComponents/dropdown';
import DropdownItem from '../../../../sharedComponents/dropdownItem';

interface IDemographics {
  userRole: string;
  watch: any;
  errors: any;
  handleDropdown: (e: React.ChangeEvent, dropdownName: any) => void;
}

const Demographics = ({
  userRole,
  watch,
  errors,
  handleDropdown
}: IDemographics) => {
  const classes = styles();

  return (
    <div>
      <Heading name="Demographics" />
      <div className={classes.inputsContainer}>
        <Dropdown
          disabled={userRole === ROLES.ANALYST}
          multiple
          selectedItem={watch('race')}
          errorMsg={errors.race?.message}
          label="What is the patient's race?*"
          customSelectClass={classes.customInput}
          handleItems={e => handleDropdown(e, 'race')}
        >
          {RACE_OPTIONS.map(({ id, name }) => (
            <DropdownItem key={id} value={id}>
              {name}
            </DropdownItem>
          ))}
        </Dropdown>
        <Dropdown
          disabled={userRole === ROLES.ANALYST}
          multiple
          selectedItem={watch('ethnicity')}
          errorMsg={errors.ethnicity?.message}
          label="Is the patient of Hispanic, Latino, or Spanish ethnicity?*"
          customSelectClass={classes.customInput}
          handleItems={e => handleDropdown(e, 'ethnicity')}
        >
          {ETHNICITY_OPTIONS.map(({ id, name }) => (
            <DropdownItem key={id} value={id}>
              {name}
            </DropdownItem>
          ))}
        </Dropdown>
        <Dropdown
          disabled={userRole === ROLES.ANALYST}
          multiple
          selectedItem={watch('militaryStatus')}
          label="What’s your military status?*"
          customSelectClass={classes.customInput}
          handleItems={e => handleDropdown(e, 'militaryStatus')}
        >
          {MILITARY_OPTIONS.map(({ id, name }) => (
            <DropdownItem key={id} value={id}>
              {name}
            </DropdownItem>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

export default Demographics;
