import { gql } from '@apollo/client';

export const SEARCH_SITES = gql`
  query SearchSites($data: SearchSiteInput!) {
    searchSites(data: $data) {
      data {
        id
        title
        address
        city
        state
        zip
        primaryPhone
        fax
        parentSite {
          title
        }
        active
        deactivationDate
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      count
    }
  }
`;
